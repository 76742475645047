import { useState } from "react";
import { Modal } from "react-overlays";

import "./styles.css";

type Props = {
    show: boolean;
}

export const CookieModal: React.FC<Props> = ({ show }) => {
    const [showModal, setShowModal] = useState<boolean>(show);

    const onCloseClick = () => {
        setShowModal(false)
    }
    
    return (
        <Modal id="cookie_modal" show={showModal}>
            <div>
                <h3>Cookie Hinweis</h3>
                <span>Mit dem Nutzen der Webseite erklären Sie sich mit dem Einsatz funktionaler Cookies einverstanden. Mehr Infos unter "Impressum".</span>
                <button onClick={onCloseClick}>Okay</button>
            </div>
        </Modal>
    )
}