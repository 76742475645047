import { MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import "./styles.css";

type Props = {
    id: string;
    label: string;
    path: string;
    active: boolean;
    onClick: MouseEventHandler;
}

export const NavigationButton: React.FC<Props> = ({ id, label, path, active, onClick }) => {
    return (
        <Link to={path} className={active ? "navigationButton navigationButton_active" : "navigationButton" } onClick={onClick} >
            <div id={id}>{`[ ${label} ]`}</div>
        </Link>
    );
}