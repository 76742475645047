import { Article } from '../../Article/Article';
import { Page } from '../../Page/Page';

export const Impressum: React.FC = () => {
    return (
        <Page>
            <Article title="IMPRESSUM">
                <p>
                    David Brune
                    <br />
                    20259 Hamburg
                    <br />
                </p>
                <p>
                    Für Fragen und Anregungen:
                    <br />
                    <a href="mailto:Tarrokk@gmail.com">Tarrokk@gmail.com</a>
                </p>
            </Article>
            <Article title="PRIVACY & COOKIE POLICY">
                <p>
                    Cookies sind kleine Textdateien, die auf dem Computer
                    abgelegt werden, um Daten zu erfassen und gewisse Funktionen
                    zu ermöglichen. Diese Seite nutzt folgende Cookies:
                </p>
                <ul>
                    <li>
                        Google Analytics, um Nutzerinteraktion mit der Webseite
                        zu erfassen
                    </li>
                </ul>
                <p>
                    Mit dem Verwenden dieser Webseite erklären Sie sich mit dem
                    Nutzen der Cookies einverstanden.
                </p>
            </Article>
        </Page>
    );
};
