import styles from './Playlist.module.css';
import { Playlist as PlaylistType, Video } from '../../../../api';
import { MouseEventHandler } from 'react';

type Props = {
    playlist: PlaylistType;
    onClick: MouseEventHandler;
};

export const Playlist: React.FC<Props> = ({ playlist, onClick }) => {
    const { title, thumbnail, videos } = playlist;

    return (
        <section className={styles.playlist} onClick={onClick}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.thumbnail_container}>
                <img
                    className={styles.thumbnail}
                    src={thumbnail}
                    width="100%"
                    title="hello"
                />
            </div>
        </section>
    );
};
