import { ReactNode } from "react";
import "./styles.css";


type Props = {
    children: ReactNode;
}

export const Page: React.FC<Props> = ({ children }) => {
    return (
        <div className="page">
            {children}
        </div>
    );
};