import { Fragment } from "react";

import "./styles.css";
import { Page } from "../../Page/Page";

export const Community: React.FC = () => {
    return (
        <Page>
            <div id="grid_container">
                <div id="header_container">
                    <h1>WERDE TEIL UNSERER COMMUNITY AUF DISCORD!</h1>
                </div>
                <div id="discord_container">
                    <iframe src="https://discord.com/widget?id=774221532020736000&theme=dark" height="500" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                </div>
                <div id="discord_description_container">
                    <ul>
                        <li>Tausche Dich mit anderen über Tarrokk's Videos aus!</li>
                        <li>Stelle Deine eigenen Bauwerke vor!</li>
                        <li>Finde Freunde zum gemeinsamen Spielen!</li>
                    </ul>
                </div>
                <div id="youtube_container">
                    <span>Warum eigentlich nicht mehr auf YouTube? Mir persönlich gefällt die Richtung nicht, in die sich YouTube entwickelt. 
                        Überall immer mehr Werbung, zeitgleich bekomme ich ständig unberechtigte Strikes auf meine Videos, sodass dubiose Firmen meine Werbeeinnahmen einstreichen.
                        Daher habe ich entschieden, meine Videos nicht mehr auf YouTube anzubieten. Stattdessen werde ich die beliebtesten nun hier auf meiner eigenen Webseite hosten, damit ihr - meine Community - 
                        sie weiterhin genießen könnt. Und das sogar ab sofort werbefrei!</span>
                </div>
            </div>
        </Page>
    )
}