import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { NavigationBar } from './components/NavigationBar/NavigationBar';
import { NavigationButton } from './components/NavigationButton/NavigationButton';
import { Videos } from './components/pages/videos/Videos';
import { Software } from './components/pages/software/Software';
import { Community } from './components/pages/community/Community';
import { Impressum } from './components/pages/impressum/Impressum';
import { CookieModal } from './components/CookieModal/CookieModal';
import { Home } from './components/pages/home/Home';

function App() {
    const [activePageIndex, setActivePageIndex] = useState<number>(0);

    return (
        <div className="App">
            <BrowserRouter>
                <NavigationBar id="nav">
                    <NavigationButton
                        id="navbutton_home"
                        label="home"
                        path="/"
                        active={activePageIndex === 0}
                        onClick={() => setActivePageIndex(0)}
                    />
                    <NavigationButton
                        id="navbutton_games"
                        label="software"
                        path="/software"
                        active={activePageIndex === 2}
                        onClick={() => setActivePageIndex(2)}
                    />
                    <NavigationButton
                        id="navbutton_videos"
                        label="videos"
                        path="/videos"
                        active={activePageIndex === 1}
                        onClick={() => setActivePageIndex(1)}
                    />
                    <NavigationButton
                        id="navbutton_impressum"
                        label="impressum"
                        path="/impressum"
                        active={activePageIndex === 4}
                        onClick={() => setActivePageIndex(4)}
                    />
                </NavigationBar>
                <main>
                    <Routes>
                        <Route path="/" Component={Home} />
                        <Route path="/videos" Component={Videos} />
                        <Route path="/software" Component={Software} />
                        <Route path="/community" Component={Community} />
                        <Route path="/impressum" Component={Impressum} />
                    </Routes>
                </main>
            </BrowserRouter>
            <CookieModal show={true} />
        </div>
    );
}

export default App;
