import './styles.css';
import overdrive from '../../../resources/images/overdrive.png';
import bliblogo from '../../../resources/images/blib.png';
import { Page } from '../../Page/Page';
import { Article } from '../../Article/Article';
import { SoftwareTable } from '../../SoftwareTable/SoftwareTable';
import { useEffect, useState } from 'react';
import { SoftwaresList } from '../../../../../api';

export const Software: React.FC = () => {
    const [softwares, setSoftwares] = useState<SoftwaresList>({
        overdrive: [],
        blib: [],
    });

    useEffect(() => {
        fetch('/software', {
            method: 'GET',
        })
            .then((response) => response.json())
            .then(
                (json) => setSoftwares(json),
                (error) => {
                    console.error(error);
                },
            );
    }, []);

    return (
        <Page>
            <Article title="OVERDRIVE">
                <img src={overdrive} className="overdrive_header" />
                <p>
                    … is a music app that I created out of the necessity to have
                    a specialized software for playing goal jingles at sport
                    events. As a speaker I could not find a music player that
                    fit my needs, so I built my own with focus on track
                    organization and ease-of-use.
                </p>
                <p>Main features include:</p>
                <ul>
                    <li>
                        touch-optimized interface for quickly starting goal
                        jingles
                    </li>
                    <li>display multiple playlists next to each other</li>
                    <li>
                        single play and auto play for each playlist individually
                    </li>
                </ul>
                <p>
                    The software is built with Electron, React and TypeScript
                    and currently available for Windows. You can download the
                    latest version here:
                </p>
                <SoftwareTable>
                    <thead>
                        <tr>
                            <td>File</td>
                            <td>Platform</td>
                        </tr>
                    </thead>
                    <tbody>
                        {softwares?.overdrive.map((item) => {
                            const splitUrl = item.url.split('/');
                            const filename = splitUrl[splitUrl.length - 1];

                            return (
                                <tr>
                                    <td>
                                        <a href={item.url}>{filename}</a>
                                    </td>
                                    <td>{item.platform}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </SoftwareTable>
            </Article>
            <Article title="BliB" className="game_section">
                <img src={bliblogo} />
                <p>
                    ... is a small arcade game that tests your reaction and
                    memory at the same time. Switch your central BliB from black
                    to white by tapping the screen and match it with the
                    incoming smaller BliBs. But be careful - you can only see
                    one color of BliBs at the same time!
                </p>
                <p>
                    BliB is built with Unity3D Game Engine and C# and was
                    released to the Google Play Store. Since I dropped the
                    support, it is no longer available.
                </p>
            </Article>
        </Page>
    );
};
