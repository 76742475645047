import { useEffect, useState } from 'react';
import { Playlist } from '../../Playlist/Playlist';

import styles from './Videos.module.css';
import { Video } from '../../../../../api';
import { Page } from '../../Page/Page';
import { PlaylistModal } from '../../PlaylistModal/PlaylistModal';

export const Videos: React.FC = () => {
    const [playerSettings, setPlayerSettings] = useState<{
        showPlayer: boolean;
        video: Video;
    }>({
        showPlayer: false,
        video: {
            title: '',
            src: '',
        },
    });
    const [playlists, setPlaylists] = useState<any[] | undefined>(undefined);
    const [selectedPlaylist, setSelectedPlaylist] = useState<
        object | undefined
    >(undefined);

    useEffect(() => {
        fetch('/test', {
            method: 'GET',
        })
            .then((response) => response.json())
            .then(
                (json) => setPlaylists(json),
                (error) => {
                    console.error(error);
                },
            );
    }, []);

    const onThumbnailClick = (playlist: any) => {
        console.log(playlist);
        setSelectedPlaylist(playlist);
        // setPlayerSettings({ showPlayer: true, video });
    };

    const closeModal = () => {
        setSelectedPlaylist(undefined);
        // setPlayerSettings({ ...playerSettings, showPlayer: false });
    };

    const content =
        playlists !== undefined ? (
            playlists.map((playlist) => {
                return (
                    <Playlist
                        key={playlist.label}
                        playlist={playlist}
                        onClick={() => onThumbnailClick(playlist)}
                    />
                );
            })
        ) : (
            <div>Videos could not be loaded... Sorry :(</div>
        );

    return (
        <Page>
            <div
                id="playlists_container"
                className={styles.playlists_container}
            >
                {content}
            </div>
            <PlaylistModal
                playlist={selectedPlaylist}
                show={selectedPlaylist !== undefined}
                onClose={closeModal}
            />
        </Page>
    );
};
