import { ReactNode } from 'react';

import styles from './SoftwareTable.module.css';

type Props = {
    children: ReactNode;
};

export const SoftwareTable: React.FC<Props> = ({ children }) => {
    return <table className={styles.table}>{children}</table>;
};
