import { Page } from "../../Page/Page"

export const Home: React.FC = () => {
    
    
    return (
        <Page>
            <h2>Home</h2>
        </Page>
    )
}