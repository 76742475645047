import { ReactNode } from 'react';
import styles from './Article.module.css';

export type ArticleProps = {
    title: string;
    children?: ReactNode;
    className?: string;
};

export const Article: React.FC<ArticleProps> = ({
    title,
    children,
    className,
}) => {
    const classNames = `${styles.container} ${className !== undefined ? className : ''}`;

    return (
        <section className={classNames}>
            <h1 className={styles.title}>{title}</h1>
            {children}
        </section>
    );
};
