import { ReactNode } from 'react';
import './styles.css';
import logo from '../../resources/images/logo.png';

type Props = {
    id: string;
    children: ReactNode;
};

export const NavigationBar: React.FC<Props> = ({ id, children }) => {
    return (
        <nav id={id}>
            <img src={logo} id="logo" />
            {children}
        </nav>
    );
};
